import React from 'react'

import { Button, Card, CardBody, Container, Row, Col, Badge } from 'reactstrap'

import Bounce from 'react-reveal/Bounce'
import { openPopupWidget } from 'react-calendly'
import Link from 'next/link'
import ReactHtmlParser from 'react-html-parser'
import { isMobile } from 'react-device-detect'
import classNames from 'classnames'
import { getCurrencyStr, firstLetter } from 'common'
import { FormLabel, Switch } from '@mui/material'
import Image from 'next/image'
import { UserContext } from '../contexts/UserContext'
import Fab from '../components/Fab'

import data from '../data/guru-get-started.json'
import { translate } from '../translations/utils'
import useTranslation from '../hooks/UseTranslation'
import WithLocale from '../hocs/WithLocale'
import useWindowSize from '../hooks/UseWindowSize'
import { HeaderCollapsedContext } from '../contexts/HeaderCollapsedContext'
import Testimonials from '../components/SwiperTestimonial'
import { getGurusForInsta } from '../data/gurus-insta'
import Instagram from '../components/Instagram'
import Discover from '../components/Discover'
import { testimonials } from '../data/testimonials'
import imgBg from '../../public/content/img/get-started-bg.webp'
import emptyBg from '../../public/content/img/empty-bg.webp'
import gradientBg from '../../public/content/img/gradient.webp'
import characterAbsImg from '../../public/content/img/character-abs.webp'
import mainImg from '../../public/content/img/home-dashboard.png'
import dashboardImg from '../../public/content/img/get-started-dashboard_2.webp'
import characterRunImg from '../../public/content/img/character-run.webp'
import profileImg from '../../public/content/img/get-started-profile_2.webp'
import characterYogaImg from '../../public/content/img/character-yoga.webp'
import manageImg from '../../public/content/img/get-started-manage_2.webp'

export async function getStaticProps(ctx) {
  const { locale, defaultLocale } = ctx
  const gurus = await getGurusForInsta()
  return {
    revalidate: 60,
    props: {
      nav: {
        light: true,
        color: 'transparent'
      },
      data: { gurus },
      noPaddingTop: true,
      title: null,
      desc: 'La solution gratuite tout-en-un pour gérer vos paiments, réservations et communication avec vos élèves.',
      locale,
      defaultLocale
    }
  }
}

function getDemoButton(color, t, onClick, className, block) {
  return (
    <Button
      color={color}
      onClick={onClick}
      block={block}
      className={classNames('shadow', className)}
    >
      {/* <i className="far fa-paper-plane mr-2" /> */}
      {t('guru_get_started_request_demo')}
    </Button>
  )
}

const FEATURES = {
  free: [
    { title: 'guru_get_started_feature_bookings' },
    { title: 'guru_get_started_feature_calendar' },
    { title: 'guru_get_started_feature_packs' },
    { title: 'guru_get_started_feature_retreat' },
    { title: 'guru_get_started_feature_referral' },
    { title: 'guru_get_started_feature_integration' },
    { title: 'guru_get_started_feature_cash' },
    { title: 'guru_get_started_feature_invoices1' },
    { title: 'guru_get_started_feature_invoices2' },
    { title: 'guru_get_started_feature_crm' },
    { title: 'guru_get_started_feature_support' }
  ],
  premium: [
    { title: 'guru_get_started_feature_integrated_pay' },
    { title: 'guru_get_started_feature_promos' },
    { title: 'guru_get_started_feature_exports' },
    { title: 'guru_get_started_feature_waiting_list' },
    { title: 'guru_get_started_feature_advanced' },
    { title: 'guru_get_started_feature_ref_corpo' }
  ]
}

const PricingCol = ({
  title,
  subtitle,
  highlight,
  price,
  priceSubtitle,
  items,
  cta,
  isLast
}) => {
  const { t } = useTranslation()
  return (
    <Col
      key={title}
      md='6'
      className={classNames(
        'mb-xl-0 d-flex flex-column justify-content-between pb-4',
        { 'mb-0': !isLast }
      )}
    >
      <div>
        <hr />
        <h2 className='text-base subtitle text-center text-primary py-3'>
          {title}
        </h2>
        <p className='text-muted text-center mb-0'>
          <span className='h1 text-dark'>{price}</span>
          <span className='ms-2'>{t('/month')}</span>
        </p>
        {priceSubtitle}
        <hr />
        <span className='font-weight-bold'>{subtitle}</span>
        <ul className='fa-ul my-4'>
          {items?.map((item) => (
            <li
              key={item.title}
              className={`mb-2 ${item.enabled ? '' : 'text-muted'}`}
            >
              {item.enabled ? (
                <span className='fa-li text-primary'>
                  <i className='fa fa-check text-primary' />
                </span>
              ) : (
                <span className='fa-li'>
                  <i className='fa fa-check text-times' />
                </span>
              )}
              {item.title}
              {item.soon && (
                <Badge color='primary-light' pill className='text-xs ml-2'>
                  {t('soon')}
                </Badge>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className='text-center'>{cta}</div>
    </Col>
  )
}

const MainContent = () => {
  const { t } = useTranslation()
  return (
    <div className='d-flex flex-column justify-content-center align-items-center w-100 h-100'>
      <div
        className='p-4 shadow'
        style={{
          backdropFilter: 'blur(10px)', // Applies the blur effect
          backgroundColor: 'rgba(255, 255, 255, 0.5)', // Adds a semi-transparent background color
          borderRadius: '10px' // Optional: Adds rounded corners for a softer look
        }}
      >
        <h2 className='display-5 font-weight-bold  text-primary'>
          {ReactHtmlParser(t(data.title1))}
        </h2>
        <h2 className='display-5 font-weight-bold text-primary'>
          {ReactHtmlParser(t(data.title2))}
        </h2>
        <h1
          className='h6 font-weight-bold'
          // style={{ color: '#8E24AA' }}
        >
          {t('guru_get_started_block_0_subtitle')}
        </h1>
      </div>
      <div className='d-flex flex-column px-6 text-center align-end w-100 py-4'>
        <p className='font-weight-bold'>
          {ReactHtmlParser(t('guru_get_started_block_0_easy_boarding'))}
        </p>
        <div className='d-flex justify-content-center'>
          <Link href='#transfer' passHref>
            <Badge
              className='d-inline-block text-lg bg-white text-black pointer shadow-lg'
              color='link'
            >
              {ReactHtmlParser(t('guru_get_started_block_0_easy_boarding_cta'))}
            </Badge>
          </Link>
        </div>
      </div>
    </div>
  )
}

const Page = (props) => {
  const { t } = useTranslation()
  const { loggedUser } = React.useContext(UserContext)
  const [isYearly, setIsYearly] = React.useState(true)
  const { setNavBarColor, setNavBarLight, setNavBarDark } = React.useContext(
    HeaderCollapsedContext
  )
  const bgRef = React.useRef()
  const size = useWindowSize() // hack to redraw

  const prefill = loggedUser
    ? {
        ...loggedUser,
        name: `${loggedUser.name_display} ${loggedUser.lastname}`
      }
    : {}
  const demoOnClick = () =>
    openPopupWidget({ url: process.env.NEXT_PUBLIC_CALENDLY_URL, prefill })

  const ctaSignup = (className, block) => (
    <Link href='/signup?isGuru=1'>
      <Button
        block={block}
        color={`primary ${className ?? ''}`}
        className='mr-2 shadow'
      >
        {/* <i className="far fa-paper-plane mr-2" /> */}
        {t('start_free')}
      </Button>
    </Link>
  )

  React.useEffect(() => {
    setNavBarDark(true)
    setNavBarLight(false)
    if (!isMobile) setNavBarColor('transparent')
  }, [])

  React.useEffect(() => {
    const handleScroll = () => {
      const th = isMobile ? 0 : bgRef.current?.offsetHeight * 0.6
      const show = window.scrollY > th
      if (show) {
        setNavBarColor('white')
        setNavBarLight(true)
        setNavBarDark(false)
      } else {
        setNavBarColor('transparent')
        setNavBarLight(false)
        setNavBarDark(true)
      }
    }
    // const debounced = debounce(handleScroll, 100, { trailing: true })

    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const ctaContactMeLight = (className, block) =>
    getDemoButton('negative-primary', t, demoOnClick, className, block)

  const getMarketingElement = (
    imgRight,
    characterUrl,
    imgUrl,
    blockTitle,
    blockContent,
    alt
  ) => {
    const text = (
      <Col
        xl='5'
        lg='6'
        xs='12'
        className={classNames('order-first', {
          'order-lg-first text-lg-right pr-lg-4 ml-auto': imgRight,
          'order-lg-last pl-lg-4 mr-auto': !imgRight
        })}
      >
        <h5 className='text-first-letter'>{ReactHtmlParser(t(blockTitle))}</h5>
        <div className='mb-5 mb-lg-0'>{ReactHtmlParser(t(blockContent))}</div>
        <div className='pt-md-4 text-center d-none d-lg-block'>
          <div
            className='img-fluid position-relative'
            style={{ height: '140px' }}
          >
            <Image
              loading='lazy'
              src={characterUrl}
              layout='fill'
              objectFit='contain'
              alt=''
            />
          </div>
        </div>
      </Col>
    )
    const img = (
      <Col
        xl='5'
        lg='6'
        xs='12'
        className={classNames('order-last', {
          'order-lg-last pl-lg-4 mr-auto': imgRight,
          'order-lg-first pr-lg-4 ml-auto': !imgRight
        })}
      >
        <div className='img-fluid'>
          <Image loading='lazy' src={imgUrl} objectFit='contain' alt={alt} />
        </div>
      </Col>
    )
    const out = (
      <Row className='py-5'>
        {img}
        {text}
      </Row>
    )

    return imgRight ? <Bounce right>{out}</Bounce> : <Bounce left>{out}</Bounce>
  }

  const free = FEATURES.free.map((item) => ({
    title: t(item.title),
    enabled: true,
    soon: item.soon
  }))
  const premium = FEATURES.premium.map((item) => ({
    title: t(item.title),
    enabled: true,
    soon: item.soon
  }))

  const proCta = (
    <Link href={loggedUser ? '/personal#premium' : '/signup?isGuru=1&isDemo=1'}>
      <Button block color='primary'>
        {t('try_for_free')}
      </Button>
    </Link>
  )

  return (
    <div
      style={{
        overflowX: 'hidden'
      }}
    >
      {/* <Fab onClick={demoOnClick}>{t('guru_get_started_request_demo')}</Fab> */}
      <section
        ref={bgRef}
        className='position-relative overlay'
        style={{
          background: 'linear-gradient(135deg, #E8EAF6 0%, #ff9966 150%)' // Soft gradient background
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(255, 255, 255, 0.1)',
            backgroundImage:
              "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='0 0 100 100'%3E%3Cfilter id='noise'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.8' numOctaves='4' stitchTiles='stitch' /%3E%3C/filter%3E%3Crect width='100' height='100' filter='url(%23noise)' /%3E%3C/svg%3E\")",
            opacity: 0.15, // Control grain visibility
            pointerEvents: 'none' // Makes the overlay unclickable
          }}
        ></div>
        <Container
          className='overlay-content d-flex flex-column justify-content-center'
          style={{
            height: '85vh',
            marginTop: '200px',
            borderRadius: '12px' // Optional: adds a rounded look
          }}
        >
          <Row className='mb-4 d-none d-md-flex'>
            <Col
              md={{
                size: 5
              }}
              lg={{
                size: 5
              }}
            >
              <MainContent />
            </Col>
            <Col
              md={{
                size: 7
              }}
              lg={{
                size: 7
              }}
              style={{
                paddingTop: '25vh'
              }}
              // className='mr-auto'
            >
              <div className='img-fluid '>
                <Image loading='lazy' src={mainImg} objectFit='contain' />
              </div>
            </Col>
          </Row>
          <div
            className='d-flex d-md-none flex-column'
            style={{ paddingTop: '33%' }}
          >
            <MainContent />
            <div
              className='img-fluid pt-4'
              // style={{ bottom: '0', transform: 'translate(0, 33%)' }}
            >
              <Image loading='lazy' src={mainImg} objectFit='contain' />
            </div>
          </div>
        </Container>
      </section>
      <Container
        className='z-index-20 position-relative'
        style={{ transform: 'translateY(-50%)' }}
      >
        {/* above class necessary to appear on top... */}
        <div className='d-none d-sm-flex justify-content-center'>
          <Link href='/signup?isGuru=1' as='/signup?isGuru=1'>
            <Button className='mr-1 btn-lg shadow' color='primary'>
              {t('start_free')}
            </Button>
          </Link>
          {ctaContactMeLight('btn-lg shadow ml-1', false)}
        </div>
        <div className='d-sm-none'>
          {ctaSignup('btn-lg shadow', true)}
          {ctaContactMeLight('btn-lg shadow', true)}
        </div>
      </Container>
      <section>
        <div className='text-center pt-5 pb-lg-4'>
          <h2 className='text-shadow mb-4'>
            {ReactHtmlParser(t('guru_get_started_block_gurus_title'))}
          </h2>
        </div>
      </section>
      <Instagram priority data={props.data.gurus} />
      <section className='pt-6'>
        <div className='text-center'>
          <h2 className='text-shadow'>
            {ReactHtmlParser(t('guru_get_started_block_0_title'))}
          </h2>
          {/* <p className="subtitle text-secondary">{t("guru_get_started_block_0_subtitle")}</p> */}
        </div>
        <Row>
          <Col xs='11' xl='10' className='mx-auto'>
            {getMarketingElement(
              false,
              characterAbsImg,
              dashboardImg,
              'guru_get_started_title_numbered_0_title',
              'guru_get_started_title_numbered_0_content',
              'dashboard illustration'
            )}
            {getMarketingElement(
              true,
              characterRunImg,
              profileImg,
              'guru_get_started_title_numbered_1_title',
              'guru_get_started_title_numbered_1_content',
              'profile illustration'
            )}
            {getMarketingElement(
              false,
              characterYogaImg,
              manageImg,
              'guru_get_started_title_numbered_2_title',
              'guru_get_started_title_numbered_2_content',
              'crm illustration'
            )}
            {/* <Container className="d-flex justify-content-center">
                    {ctaSignup("btn-lg")}
                    {ctaContactMeLight("btn-lg")}
                </Container> */}
          </Col>
        </Row>
      </section>
      <section
        className='overlay py-6'
        id='transfer'
        style={{
          background: 'linear-gradient(135deg, #E8EAF6 0%, #ff9966 150%)' // Soft gradient background
        }}
      >
        <Container className='overlay-content'>
          <div
            className='text-center pb-lg-4 p-4 shadow'
            style={{
              backdropFilter: 'blur(10px)', // Applies the blur effect
              backgroundColor: 'rgba(255, 255, 255, 0.5)', // Adds a semi-transparent background color
              borderRadius: '10px' // Optional: Adds rounded corners for a softer look
            }}
          >
            {/* <p className="subtitle text-secondary">{t("guru_get_started_block_1_subtitle")}</p> */}
            <h2 className='mb-5'>
              {ReactHtmlParser(t('guru_get_started_block_1_title'))}
            </h2>
            <p className='mb-1 lead'>
              {ReactHtmlParser(t('guru_get_started_block_1_content_0'))}
            </p>
            <Link
              href="mailto:info@gurubay.co?subject=Question pour transfert d'activité vers Gurubay&body=Bonjour!%0A%0AJe souhaiterais en savoir plus sur le transfert d'activité vers Gurubay.%0A%0AJe vous invite à me recontacter sur cet e-mail pour échanger de mes besoins spécifiques.%0A%0AMerci !"
              passHref
            >
              <Badge
                className='d-inline-block text-xl bg-white text-black pointer'
                color='link'
              >
                {ReactHtmlParser(
                  t('guru_get_started_block_0_easy_boarding_cta')
                )}
              </Badge>
            </Link>
          </div>
        </Container>
      </section>
      <section className='position-relative overlay py-5'>
        <div className='bg-image'>
          <Image
            quality={100}
            src={emptyBg}
            layout='fill'
            objectFit='cover'
            alt=''
            loading='lazy'
          />
        </div>
        <Container className='overlay-content'>
          <div className='text-center'>
            <h2 className='mb-4 text-white'>
              {t('index_testimonials_subtitle')}
            </h2>
          </div>
          <Testimonials pagination t={t} data={testimonials} />
        </Container>
      </section>
      <section className='py-6 bg-gray-100'>
        <Container>
          <div className='text-center pb-lg-4'>
            {/* <p className="subtitle text-secondary">{t("guru_get_started_block_1_subtitle")}</p> */}
            <h2 className='mb-5'>
              {ReactHtmlParser(t('guru_get_started_block_retreat_title'))}
            </h2>
            <p className='mb-1 lead'>
              {ReactHtmlParser(t('guru_get_started_block_1_content_0'))}
            </p>
          </div>
        </Container>
      </section>
      <Discover
        className='py-6'
        title={data.hesitation.title}
        subtitle={data.hesitation.subtitle}
        blocks={data.hesitation.blocks}
      />
      {/* <div>
                <section className="py-6 position-relative">
                    <Container>
                        <Row>
                            <Col lg="2" />
                            <Col lg="8">
                                <div className="bg-white rounded-lg shadow p-md-5 p-3">
                                    <h2 className="mb-2 text-shadow">{t("guru_get_started_video_header")}</h2>
                                    <YouTube videoId="cYYVutqd-6s" containerClassName={"youtube-container"} />
                                </div>
                            </Col>
                            <Col lg="2" />
                        </Row>
                    </Container>
                </section>
            </div> */}
      <section className='bg-gray-100'>
        <Container
          className='d-flex justify-content-center'
          style={{ transform: 'translateY(-50%)' }}
        >
          {ctaSignup('btn-lg')}
          {ctaContactMeLight('btn-lg d-none d-sm-inline')}
        </Container>
      </section>
      {/* <section className="py-6">
                <Container>
                    <div className="text-center pb-lg-4">
                        <p className="subtitle text-secondary">{t("guru_get_started_block_1_subtitle")}</p>
                        <h2 className="mb-5">{t("guru_get_started_block_1_title")}</h2>
                        <p className="mb-1 lead">{t("guru_get_started_block_1_content_0")}</p>
                    </div>
                </Container>
            </section> */}
      <section className='py-6 position-relative overlay'>
        <div className='bg-image'>
          <Image
            quality={100}
            src={gradientBg}
            layout='fill'
            objectFit='cover'
            alt=''
            loading='lazy'
          />
        </div>
        <Container>
          <div className='overlay-content text-white py-lg-5'>
            <h3 className='display-3 font-weight-bold text-shadow mb-4'>
              {t('guru_get_started_block_2_title')}
            </h3>
          </div>
        </Container>
      </section>
      <Container className='scroll-margin-top' id='features'>
        <Card className='mb-5 mb-lg-0 border-0 shadow h-100 mt-n4'>
          <CardBody>
            <div className='text-center'>
              {/* <h5 className='mt-3'>
                                Débloquez toutes les fonctionnalités <span className='fancy-underline'>gratuitement</span> en parrainant deux profs.
                            </h5> */}
              <h5 className='my-3'>{t('pricing_title')}</h5>
              {/* <Link href='/get-started#referral'>
                                <Button color="link">
                                    {t("know_more")}
                                </Button>
                            </Link> */}
              <div>
                <FormLabel className='font-weight-bold'>
                  {firstLetter(t('monthly'))}
                </FormLabel>
                <Switch
                  checked={isYearly}
                  onChange={() => setIsYearly((y) => !y)}
                  color='primary'
                />
                <FormLabel className='font-weight-bold'>
                  {firstLetter(t('yearly'))}
                </FormLabel>
              </div>
            </div>
            <Row>
              <PricingCol
                title={t('plan_basic')}
                subtitle={t('plan_basic_header')}
                items={free}
                price={getCurrencyStr(0, 'EUR')}
                priceSubtitle={
                  <>
                    <div className='text-muted text-center'>
                      {t('basic_plan_price_subtitle')}
                    </div>
                    <div className='text-muted text-center'>
                      {ReactHtmlParser(t('fees_included'))}
                      <br />
                      <Link href='/faq#pricing'>
                        <a>{t('know_more')}</a>
                      </Link>
                    </div>
                  </>
                }
                cta={
                  <Link href='/signup?isGuru=1'>
                    <Button block color='outline-primary'>
                      {t('start_now')}
                    </Button>
                  </Link>
                }
              />
              <PricingCol
                title={t('premium')}
                subtitle={t('all_in_pro_plus')}
                items={premium}
                price={getCurrencyStr(isYearly ? 25 : 39, 'EUR')}
                priceSubtitle={
                  <>
                    <div className='text-muted text-center'>
                      {t('premium_plan_price_subtitle')}
                    </div>
                    <div className='text-muted text-center'>
                      {ReactHtmlParser(t('fees_included'))}
                      <br />
                      <Link href='/faq#pricing'>
                        <a>{t('know_more')}</a>
                      </Link>
                    </div>
                  </>
                }
                isLast
                cta={proCta}
              />
            </Row>
          </CardBody>
          {/* <CardFooter className='bg-white scroll-margin-top' id="referral">
                        <p>
                            Pour bénéficier de toutes les fonctionnalités gratuitement, il vous suffit de parrainer deux profs. Après leur première réservation donnée via Gurubay, vous débloquerez automatiquement toutes les fonctionnalités gratuitement pendant 6 mois.
                        </p>
                        <p>
                            Pour commencer à parrainer des profs, rendez-vous dans la rubrique <Link href="/personal#premium"><a className='font-weight-bold'>Mon compte</a></Link> !
                        </p>
                    </CardFooter> */}
        </Card>
      </Container>
    </div>
  )
}

export default WithLocale(Page)
